import getEnvValue from 'commons/getEnvValue';
import { getDefaultSelectedOptions } from 'components/consumer/PDP/helpers/pdpOptionUtils';
import useFlashSaleList from 'hooks/useFlashSaleList';
import { useMemo } from 'react';

// Get default values for options, in extended selected options format
const usePDPDefaultOptionValues = ({ id, productOptions }) => {
  // Get flash sale products and variations
  const isUnitTestEnv = getEnvValue('JB_UNIT_TEST_ENV') === 'jest';

  const {
    products: flashSaleProducts,
    variations: flashSaleVariations,
  } = useFlashSaleList(undefined, { skip: isUnitTestEnv });

  // Populate the default selected option values for this product
  const defaultSelectedOptionsFull = useMemo(() => {
    if (!productOptions) {
      return null;
    }

    const isProductInFlashSale =
      !!flashSaleProducts?.length && flashSaleProducts.includes(id);

    const flashSaleVariation = isProductInFlashSale
      ? flashSaleVariations[id]
      : {};

    return getDefaultSelectedOptions(productOptions, null, flashSaleVariation);
  }, [productOptions, flashSaleProducts, flashSaleVariations, id]);

  // Convert these default selected option values to the extended format
  const defaultSelectedOptionsExtended = useMemo(() => {
    if (defaultSelectedOptionsFull) {
      return Object.keys(defaultSelectedOptionsFull).reduce((acc, optionId) => {
        const option = productOptions?.find(
          ({ id: oId }) => +oId === +optionId
        );
        acc[optionId] = {
          value: option
            ? option.values.find(
                ({ id: oId }) =>
                  `${oId}` === `${defaultSelectedOptionsFull[optionId].id}`
              )
            : {},
        };
        return acc;
      }, {});
    }

    return null;
  }, [productOptions, defaultSelectedOptionsFull]);

  return defaultSelectedOptionsExtended;
};

export default usePDPDefaultOptionValues;
