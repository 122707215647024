export const isPDPQueryVariablesEqual = (a, b) => {
  if ((a.id && !b.id) || (!a.id && b.id) || a.id !== b.id) {
    return false;
  }
  if ((a.slug && !b.slug) || (!a.slug && b.slug) || a.slug !== b.slug) {
    return false;
  }

  return true;
};

export const getPriceDetailsUtil = (
  priceObj,
  promotion,
  optionPrice,
  isClearance
) => {
  const priceWithOptionsPrice = priceObj.original_price + optionPrice;

  const tiers = promotion?.tiers;
  const hasTieredDiscount = !!tiers;
  const { minDiscount, maxDiscount } =
    hasTieredDiscount && tiers?.length
      ? tiers.reduce(
          (acc, tier) => {
            // get the highest discount irrespective of min_cart
            if (acc.maxDiscount < tier.discount) {
              acc.maxDiscount = tier.discount; // The maxDiscount gets updated to the latest applicable tier
            }
            // get the highest possible tier's discount
            if (
              acc.minDiscount < tier.discount &&
              priceWithOptionsPrice >= tier.min_cart
            ) {
              acc.minDiscount = tier.discount; // The minDiscount is updated to the first applicable discount
            }
            return acc;
          },
          { minDiscount: 0, maxDiscount: 0 }
        )
      : { minDiscount: 0, maxDiscount: 0 };

  const salePriceWithOptionsPrice = isClearance
    ? priceObj.final_price + optionPrice
    : priceObj.discount_percent
    ? priceWithOptionsPrice -
      Math.round((priceWithOptionsPrice * priceObj.discount_percent) / 100)
    : priceWithOptionsPrice;

  const leastTieredDiscountedPrice = hasTieredDiscount
    ? priceWithOptionsPrice -
      Math.round((priceWithOptionsPrice * minDiscount) / 100)
    : null;

  const maxTieredDiscountedPrice = hasTieredDiscount
    ? priceWithOptionsPrice -
      Math.round((priceWithOptionsPrice * maxDiscount) / 100)
    : null;

  const hasDiscount =
    salePriceWithOptionsPrice < priceWithOptionsPrice ||
    (hasTieredDiscount &&
      (leastTieredDiscountedPrice < priceWithOptionsPrice ||
        maxTieredDiscountedPrice < priceWithOptionsPrice));
  return {
    hasDiscount,
    hasTieredDiscount,
    leastTieredDiscountedPrice,
    maxTieredDiscountedPrice,
    priceWithOptionsPrice,
    salePriceWithOptionsPrice,
  };
};

export const findAvailabilityAndVisibility = attributes => {
  let availability = 0;
  let visibility = 0;
  let found = 0;

  for (let i = 0; i < attributes.length; i++) {
    if (attributes[i].code === 'item_availability') {
      availability = attributes[i].value;
      found += 1;
    }

    if (attributes[i].code === 'visibility') {
      visibility = attributes[i].value;
      found += 1;
    }

    if (found >= 2) {
      break;
    }
  }

  return { availability, visibility };
};
