import queryString from 'query-string';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const usePDPUpdateQueryParams = () => {
  const history = useHistory();

  const { pathname = '', search: queryParamsString = '' } =
    history.location ?? {};

  const updateUrlQueryParams = useCallback(
    (selectedOption, optionValue) => {
      // set the url param if it's not already set
      const selectedOptionName = selectedOption?.name
        ?.replace(/[_-\s]/g, '_')
        .replace('rugs', 'size')
        .toLowerCase();

      const selectedOptionAlternateName =
        selectedOption?.original_name?.replace(/[_-\s]/g, '_').toLowerCase() ||
        '';

      const selectedOptionValue = optionValue?.value
        ?.trim()
        .replace(/[_-\s]/g, '_')
        .toLowerCase();

      const queryParams = queryString.parse(queryParamsString);

      const updatedQueryParams = queryString.stringify({
        ...queryParams,
        ...(queryParams[selectedOptionAlternateName]
          ? { [selectedOptionAlternateName]: selectedOptionValue }
          : { [selectedOptionName]: selectedOptionValue }),
      });

      // Only update the url if the query param has changed
      if (updatedQueryParams !== queryParamsString?.replace('?', '')) {
        // If compare modal is open then after selecting fabric we redirect to
        // product page with that fabric

        if (pathname?.includes?.('/compare')) {
          history.replace?.({
            pathname: `${pathname?.split('/compare')[0]}`,
            search: updatedQueryParams,
          });
        } else {
          history.replace?.({
            search: updatedQueryParams,
          });
        }
      }
    },
    [history, queryParamsString, pathname]
  );

  return updateUrlQueryParams;
};

export default usePDPUpdateQueryParams;
