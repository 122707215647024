import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePDPRedirectDoubleQuery = () => {
  const { pathname, search: queryParamsString } = useLocation();

  useEffect(() => {
    if (queryParamsString) {
      const queryParamsStringWithoutFirstQuestionMark = queryParamsString.replace(
        '?',
        ''
      );

      if (queryParamsStringWithoutFirstQuestionMark.includes('?')) {
        const updatedQueryParamsString = queryParamsStringWithoutFirstQuestionMark.replace(
          '?',
          '&'
        );

        window.location.href = `${pathname}?${updatedQueryParamsString}`;
      }
    }
  }, [pathname, queryParamsString]);
};

export default usePDPRedirectDoubleQuery;
