import React from 'react';
import PropTypes from 'prop-types';

import defaultProductCardFallbackIcon from 'assets/icons/image-coming-soon.svg';
import isNewConsumerApp from 'commons/isNewConsumerApp';

const DefaultFallbackImage = ({ brokenImageSrc, className }) => (
  <img
    src={
      isNewConsumerApp
        ? '/static/assets/icons/image-coming-soon.svg'
        : defaultProductCardFallbackIcon
    }
    width="189"
    height="189"
    alt=""
    className={className || ''}
    data-broken-image-src={brokenImageSrc}
  />
);

DefaultFallbackImage.propTypes = {
  className: PropTypes.string,
  brokenImageSrc: PropTypes.string,
};

export default DefaultFallbackImage;
